import React from "react"

export default () => {
  return (
    <div style={{ paddingLeft: '1vw' }}>
      <h2>Privacy Policy</h2>
      <p>
        We don't track you. Only if you purchase our courses, we get your email address so that we can support you further.
        Other than that, rest assured that you are not personally tracked at all. We don't sell your personally identifiable information with third-party companies.
      </p>
    </div>
  );
};
